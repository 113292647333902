/* Spinner CSS */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }