*{
    line-height: normal;
}

.login-container {
    display: flex;
    height: 100vh;
    background-color: #e9f1fa;
  }
  
  .login-left {
    flex: 3.2;
    background-color: #f2f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .login-left img {
    max-width: 60%;
    border-radius: 10px;
  }
  
  .login-right {
    flex: 1.8;
    background-color: #ffffff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    margin-bottom: 20px;
    width: 80px; /* Ajuster en fonction de la taille de votre logo */
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    color: #555;
    margin-bottom: 30px;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 6px;
    padding-right: 40px; /* Espace pour le bouton mot de passe */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 8px;
    background-color: #0a368a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .forgot-password {
    margin-top: 15px;
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .extra-info {
    margin-top: 30px;
    color: #555;
    text-align: center;
  }
  
  .extra-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .extra-info a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .login-left {
      display: none;
    }
  
    .login-right {
      flex: 1;
      padding: 20px;
    }
  }